export const adminController = [
  {
    id: 1,
    name: "All Users",
  },
  {
    id: 2,
    name: "Add Document",
  },
  {
    id: 4,
    name: "Add Service Type",
  },
  {
    id: 5,
    name: "Add File Type",
  },
  {
    id: 6,
    name: "Send Mail Data",
  },
  {
    id: 7,
    name: "Up Skill",
  },
];
